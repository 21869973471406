<template>
  <app-card-item class="p-5 mt-6 overflow-visible relative">
    <div class="flex items-center">
      <app-rounded-picture
        :picture-url="avatarUrl"
        picture-size="32"
        icon-name="user"
        icon-size="3x"
        with-shadow
        class="mr-6 relative -top-12"
      />

      <!-- Main information -->
      <div>
        <p
          v-if="userFullName"
          class="font-bold"
        >
          {{ userFullName }}
        </p>

        <p
          v-if="resource.attributes.name"
          class="font-bold text-sm"
        >
          {{ resource.attributes.name }}
        </p>

        <p
          v-if="userRoles"
          class="text-gray-500 text-sm"
        >
          {{ userRoles }}
        </p>

        <template v-if="!hasBlockedAuthUser">
          <div
            v-if="resource.attributes.country"
            class="font-bold flex items-center"
          >
            <app-icon
              library="coolicon"
              name="location"
            />

            {{ countryCodeToFullName(resource.attributes.country) }}
          </div>
        </template>
      </div>

      <!-- Unblock -->
      <app-button
        v-if="blockedByAuthUser"
        :label="t('ttmt.common.actions.unblock_this_user')"
        class="absolute -bottom-4 left-1/2 -translate-x-1/2 w-fit"
        @click="handleUnblock"
      />

      <app-overlay
        :show="unblockConfirmModalDisplayed"
        @clicked="handleUnblockCancel"
      >
        <app-modal @closed="handleUnblockCancel">
          <app-confirm
            @cancelled="handleUnblockCancel"
            @confirmed="handleUnblockConfirm"
          >
            <p>
              {{ t('ttmt.users_relationships.messages.unblock.confirm') }}
            </p>
          </app-confirm>
        </app-modal>
      </app-overlay>
    </div>

    <div class="text-center">
      <!-- Tip -->
      <app-tip-button
        :resource="resource"
        :can-give-tip="canGiveTip"
        tip-form-mode="app"
        :style="null"
      />
    </div>
  </app-card-item>

  <div
    v-if="!hasNoInfos"
    class="bg-white rounded-2lg shadow-lg shadow-theme-500/10 p-5 pb-8 mt-6 overflow-visible relative"
  >
    <div v-if="!isEmptyContactPhone">
      <p class="text-sm font-bold mt-2">
        {{ capitalize(t('validation.attributes.contact_phone')) }}
      </p>

      <p class="text-xs">
        {{ resource.attributes.contact_phone }}
      </p>
    </div>

    <div v-if="!isEmptyContactEmail">
      <p class="text-sm font-bold mt-2">
        {{ capitalize(t('validation.attributes.contact_email')) }}
      </p>

      <p class="text-xs">
        {{ resource.attributes.contact_email }}
      </p>
    </div>
  </div>

  <!-- Institution -->
  <template
    v-if="pointOfInterest"
  >
    <app-sub-heading class="mt-6 mb-2">
      {{ capitalize(t('validation.attributes.institution')) }}
    </app-sub-heading>

    <app-card-item
      class="p-5"
    >
      <point-of-interest-folder-card
        :resource="pointOfInterest"
      />
    </app-card-item>
  </template>

  <!-- Alerts -->
  <app-alert
    v-if="hasBlockedAuthUser"
    design="warning"
    :title="t('ttmt.users.profile.alerts.blocked.title')"
    class="mt-6"
  >
    {{ t('ttmt.users.profile.alerts.blocked.message') }}
  </app-alert>

  <app-alert
    v-if="isRelationSender && relation.attributes.state === 'pending'"
    design="info"
    :title="t('ttmt.users.profile.alerts.pending_validation.title')"
    class="mt-6"
  >
    {{ t('ttmt.users.profile.alerts.pending_validation.message') }}
  </app-alert>

  <user-actions
    v-if="!guestTravellerAccess"
    :resource="resource"
    class="mt-8"
    big-icons
    @contact-updated="handleContactUpdated"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { capitalize, isNil } from 'lodash'

import { unblockContact as unblockApiContact } from '@shared/http/api'
import useAccess from '@extranet/hooks/access'
import useGeoloc from '@shared/hooks/geoloc'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import AppTipButton from '@shared/components/features/tips/AppTipButton.vue'
import PointOfInterestFolderCard from '@app/components/resources/point_of_interest/PointOfInterestFolderCard.vue'
import UserActions from '@app/components/resources/user/UserActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'contactUpdated',
])

const { t } = useI18n()
const store = useStore()
const { countryCodeToFullName } = useGeoloc()
const { getImageUrlFromResource } = useResource()
const {
  roles: userRoles,
  fullName: userFullName,
} = useUser(props)
const { guestTravellerAccess } = useAccess()

function handleContactUpdated() {
  emits('contactUpdated')
}

const relation = ref(props.resource.relationships.users_relationship)

const isRelationSender = computed(() => (
  relation.value && relation.value.attributes.sender_id === store.state.auth.user.id
))

const hasBlockedAuthUser = computed(() => (
  relation.value
    && relation.value.attributes.state === 'blocked'
    && relation.value.attributes.blocked_by !== store.state.auth.user.id
))

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))

// ---------- UNBLOCK ----------

const unblockConfirmModalDisplayed = ref(false)

function handleUnblock() {
  unblockConfirmModalDisplayed.value = true
}

function handleUnblockCancel() {
  unblockConfirmModalDisplayed.value = false
}

function handleUnblockConfirm() {
  unblockConfirmModalDisplayed.value = false
  unblockContact()
}

function unblockContact() {
  store.commit('spinner/ENABLE')

  unblockApiContact(relation.value.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.users_relationships.messages.unblock.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('contactUpdated')
    })
}

const blockedByAuthUser = computed(() => (
  relation.value
  && relation.value.attributes.state === 'blocked'
  && relation.value.attributes.blocked_by === store.state.auth.user.id
))

// ---------- TIP ----------

// Can give tip if user can receive payments
// and relation is not blocked
const canGiveTip = computed(() => (
  props.resource?.attributes?.can_receive_payments
    && props.resource.attributes.tips_enabled
    && relation.value?.attributes?.state !== 'blocked'
))

// ---------- POINT OF INTEREST ----------

const pointOfInterest = computed(() => (
  props.resource.relationships.point_of_interest
))

// ---------- AVAILABLE INFOS ----------

const isEmptyContactPhone = computed(() => (
  isNil(props.resource?.attributes?.contact_phone)
))

const isEmptyContactEmail = computed(() => (
  isNil(props.resource?.attributes?.contact_email)
))

const hasNoInfos = computed(() => (
  isEmptyContactPhone.value
  && isEmptyContactEmail.value
))
</script>

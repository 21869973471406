<template>
  <!-- Main details -->
  <app-card-item class="p-5 flex items-center mt-6 overflow-visible">
    <div class="mr-6 relative -top-12">
      <app-rounded-picture
        :picture-url="avatarUrl"
        picture-size="32"
        icon-name="user"
        icon-size="3x"
        with-shadow
      />

      <span
        v-if="!guestTravellerAccess"
        class="w-8 h-8 bg-gradient-to-br from-theme-500 to-theme-700 absolute bottom-0 right-0 rounded-md flex justify-center items-center"
        @click="handleQrModalDisplay"
      >
        <app-icon
          library="coolicon"
          name="qr_code"
          class="text-white"
          size="text-2xl"
        />
      </span>

      <app-qr-code-modal
        v-if="!guestTravellerAccess"
        :show="qrModalDisplayed"
        @closed="handleQrModalCancel"
      />
    </div>

    <div>
      <p
        v-if="userFullName"
        class="font-bold"
      >
        {{ userFullName }}
      </p>

      <p
        v-if="resource.attributes.name"
        class="font-bold text-sm"
      >
        {{ resource.attributes.name }}
      </p>

      <p
        v-if="authUserRoles"
        class="text-gray-500 mb-2 text-sm"
      >
        {{ authUserRoles }}
      </p>
    </div>
  </app-card-item>

  <router-link
    v-if="!guestTravellerAccess"
    v-slot="{ navigate }"
    custom
    :to="{ name: 'edit-profile', query: { mode: 'profile' } }"
  >
    <app-button
      display-classes="flex"
      class="mx-auto mt-5"
      :label="t('ttmt.users.profile.edit_your_profile')"
      @click="navigate"
    />
  </router-link>

  <!-- Security -->
  <h2
    v-if="!guestTravellerAccess"
    class="mt-6 mb-2 text-lg font-bold"
  >
    {{ t('ttmt.users.profile.security') }}
  </h2>

  <div
    v-if="!guestTravellerAccess"
    class="text-sm"
  >
    <!-- Email -->
    <div>
      <div class="flex items-center justify-between">
        <span class="font-bold">
          {{ capitalize(t('validation.attributes.email')) }}
        </span>

        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'edit-credential' }"
        >
          <app-button
            font-size="xs"
            emphasis="low"
            :label="t('ttmt.common.actions.manage')"
            @click="navigate"
          />
        </router-link>
      </div>

      <span
        v-if="resource.attributes?.email"
        class="text-xs"
      >
        {{ resource.attributes.email }}
      </span>

      <div
        v-else
        class="flex items-center text-xs"
      >
        <app-icon
          library="coolicon"
          name="warning"
          size="text-sm"
          class="text-yellow-500 mr-1"
        />

        <span class="text-xs">
          {{ t('ttmt.users.profile.email_missing') }}
        </span>
      </div>
    </div>

    <!-- Password -->
    <div class="mt-4">
      <div class="flex items-center justify-between">
        <span class="font-bold">
          {{ capitalize(t('validation.attributes.password')) }}
        </span>

        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'edit-profile', query: { mode: 'password' } }"
        >
          <app-button
            font-size="xs"
            emphasis="low"
            :label="resource.attributes?.has_password ? t('ttmt.common.actions.edit') : t('ttmt.common.actions.add')"
            @click="navigate"
          />
        </router-link>
      </div>

      <div
        v-if="!resource.attributes?.has_password"
        class="flex items-center text-xs"
      >
        <app-icon
          library="coolicon"
          name="warning"
          size="text-sm"
          class="text-yellow-500 mr-1"
        />

        <span class="text-xs">
          {{ t('ttmt.users.profile.password_missing') }}
        </span>
      </div>
    </div>

    <!-- Account deletion -->
    <div
      v-if="!guestTravellerAccess"
      class="mt-8"
    >
      <div class="flex items-center justify-between">
        <span class="font-bold text-red-600">
          {{ capitalize(t('ttmt.users.pages.show.sections.delete')) }}
        </span>

        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'delete-profile' }"
        >
          <app-button
            font-size="xs"
            emphasis="low"
            :label="t('ttmt.common.actions.delete')"
            color="red"
            @click="navigate"
          />
        </router-link>
      </div>
    </div>
  </div>

  <!-- Preferences -->
  <h2
    v-if="!guestTravellerAccess"
    class="mt-8 mb-2 text-lg font-bold"
  >
    {{ t('ttmt.users.profile.preferences') }}
  </h2>

  <div
    v-if="!guestTravellerAccess"
    class="text-sm"
  >
    <!-- Currency -->
    <div>
      <div class="flex items-center justify-between">
        <span class="font-bold">
          {{ capitalize(t('validation.attributes.currency')) }}
        </span>

        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'edit-profile', query: { mode: 'currency' } }"
        >
          <app-button
            font-size="xs"
            emphasis="low"
            :label="t('ttmt.common.actions.edit')"
            @click="navigate"
          />
        </router-link>
      </div>

      <span
        v-if="resource.relationships?.currency"
        class="text-xs"
      >
        {{ resource.relationships.currency.attributes.name }}
      </span>
    </div>

    <!-- Locale -->
    <div
      class="mt-4"
    >
      <div class="flex items-center justify-between">
        <span class="font-bold">
          {{ capitalize(t('validation.attributes.locale')) }}
        </span>

        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'edit-profile', query: { mode: 'locale' } }"
        >
          <app-button
            font-size="xs"
            emphasis="low"
            :label="resource.attributes?.locale ? t('ttmt.common.actions.edit') : t('ttmt.common.actions.add')"
            @click="navigate"
          />
        </router-link>
      </div>

      <span
        v-if="resource.attributes?.locale"
        class="text-xs"
      >
        {{ capitalize(t(`ttmt.locales.${resource.attributes.locale}`)) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import useAccess from '@extranet/hooks/access'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppQrCodeModal from '@app/components/features/qr_code/AppQrCodeModal.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const { getImageUrlFromResource } = useResource()
const {
  roles: authUserRoles,
  fullName: userFullName,
} = useUser(props)
const { guestTravellerAccess } = useAccess()

// ---------- QR CODE ----------

const qrModalDisplayed = ref(false)

function handleQrModalDisplay() {
  qrModalDisplayed.value = true
}

function handleQrModalCancel() {
  qrModalDisplayed.value = false
}

// ---------- AVATAR ----------

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))
</script>
